import React from "react"
import styled from 'styled-components'
import { graphql, Link } from 'gatsby'
import { Page, SiteSettings, SocialLink, SanityKeyed, SanityBlock } from '../../schema'
import { BlockRenderer, Picture, Grid } from '../components'
import { H1, H2, CyanText } from '../components/BlockRenderer'


interface IndexPageProps {
  data: {
    sanityPage: Page,
    sanitySiteSettings: SiteSettings
  }
}


const IndexPage = ({
  data: {
    sanityPage,
    sanitySiteSettings
  }
}: IndexPageProps) => {
  return (
    <Wrapper>
      <Grid>
        <ArticlesWrapper>
          <H2>Teaching Philosophies</H2>
          <ul>
            {sanityPage.articles?.map(article => (
              <li key={`article_${article._key}`} >
                <StyledLink to={`/${article.slug.current}`} >
                  <H1>{article.title} <CyanText>{article.sourceName}</CyanText></H1>
                </StyledLink>
              </li>
            ))}
          </ul>
        </ArticlesWrapper>
      </Grid>
      
      <Section threeColumn >
        {sanityPage.courseCategories?.map(category => (
          <div key={`category_${category._key}`} >
            <H2>{category.category}</H2>
            <ul>
              {category.courses?.map(course => (
                <Class key={`course_${course._key}`} >
                  <StyledLink to={`/${course.slug.current}`} >
                    <BlockRenderer>{course._rawTitle}</BlockRenderer>
                  </StyledLink>
                </Class>
              ))}
            </ul>
          </div>
        ))}
      </Section>
      
      <Biography>
        <Title>
          <H2>About</H2>
          <H1>Ksenya Samarskaya</H1>
        </Title>
        <Image>
          <Picture src={sanitySiteSettings.portrait?.asset?.url} noFadeIn />
        </Image>
        <Bio>
          <BlockRenderer>{sanitySiteSettings._rawBiography}</BlockRenderer>
        </Bio>
        <SocialLinks>
          {sanitySiteSettings.socialLinks?.map((social: SocialLink, i: number) => (
            <li key={`social_${i}`}>
              <a href={social.link} target="_blank" >
                {social.networkName}
              </a>
            </li>
          ))}
        </SocialLinks>
      </Biography>
    </Wrapper>
  )
}


const Wrapper = styled.main``


const ArticlesWrapper = styled.section`
  margin: 0 0 75px 0;
  @media only screen and (min-width: 744px) {
    margin: 0 0 125px 0;
    grid-column-end: span 2;
  }
`

const Section = styled.section<{ threeColumn?: boolean }>`
  margin: 0 0 75px 0;
  @media only screen and (min-width: 744px) {
      margin: 0 0 125px 0;
      ${props => props.threeColumn && `
        column-count: 3;
        column-gap: 40px;
      `}
  }
  div {
    padding-bottom: 35px;
    column-break-inside: avoid;
  }
`


const Class = styled.li`
  display: block;
  margin-bottom: 2em;
`

const StyledLink = styled(props => <Link {...props} />)`
  h1 {
    transition: background-color 0.15s ease-in-out;
  }
  &:hover h1 {
    background-color: rgba(255, 255, 128, 0.5);
  }
`


const Title = styled.header`
  grid-column-start: 2;
  margin-bottom: 2em;
  @media only screen and (min-width: 744px) {
    margin-bottom: 0;
  }
`

const Image = styled.div`
  grid-column-start: 1;
  margin-bottom: 2em;
  @media only screen and (min-width: 744px) {
    margin-bottom: 0;
  }
`


const Biography = styled.div`
  @media only screen and (min-width: 744px) {
    display: grid;
    grid-template-columns: 287px auto;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    grid-template-rows: repeat(3, auto);
  }
`

const Bio = styled.div`
  grid-column-start: 2;
  margin-bottom: 2em;
  @media only screen and (min-width: 744px) {
    margin-bottom: 0;
  }
`

const SocialLinks = styled.ul`
  grid-column-start: 2;
  display: flex;
  flex-direction: row;
  font-size: 18px;
  li {
    &:after {
      padding: 0 15px;
      content: '|';
    }
    &:last-of-type:after {
      content: '';
    }
    a {
      text-decoration-color: rgba(0, 0, 0, 1);
    }
  }
`



export const query = graphql`
  query HomePageQuery {
    sanitySiteSettings {
      title
      description
      _rawBiography
      socialLinks {
        link
        networkName
      }
      portrait {
        asset {
          url
        }
      }
    }
    sanityPage(slug: {current: {eq: "home"}}) {
      articles {
        _key
        title
        sourceName
        sourceLink
        slug {
          current
        }
      }
      courseCategories {
        _key
        category
        courses {
          _key
          slug {
            current
          }
          _rawTitle
        }
      }
    }
  }
`

export default IndexPage
